<template>
  <div>
    <div class="options">
      <el-button type="primary" @click="addPwdDialog">新增</el-button>
    </div>

    <el-table :data="tableData" style="width: 100%;">
      <el-table-column label="id" width="120">
        <template slot-scope="scope">
          <span>{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column label="密码" width="240">
        <template slot-scope="scope">
          <span>{{ scope.row.password }}</span>
        </template>
      </el-table-column>

      <el-table-column label="编辑">
        <template slot-scope="scope">
          <template>
            <el-popconfirm
              title="确定删除密码?"
              @confirm="delPwd(scope.row.id)"
            >
              <el-button slot="reference" size="mini">
                删除
              </el-button>
            </el-popconfirm>
          </template>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      title="新增密码"
      :visible.sync="addPwdDialogVisible"
      width="30%"
      :before-close="handleAddPwdDialogClose"
    >
      <el-input
        v-model="newPwd"
        placeholder="请输入新密码"
        maxlength="8"
        type="text"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addPwdDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addPwd">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { pwdall, pwddel, pwdadd } from '@/api'

export default {
  name: 'Config',
  components: {},
  data() {
    return {
      newPwd: '',
      addPwdDialogVisible: false,
      tableData: [],
    }
  },
  mounted() {
    this.allPwd()
  },
  methods: {
    allPwd() {
      pwdall()
        .then((res) => {
          this.tableData = res.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    addPwd() {
      pwdadd({
        password: this.newPwd,
      })
        .then((res) => {
          this.allPwd()
          this.addPwdDialogVisible = false
        })
        .catch((err) => {
          console.log(err)
        })
    },
    delPwd(id) {
      pwddel({
        id,
      })
        .then((res) => {
          this.allPwd()
        })
        .catch((err) => {
          console.log(err)
        })
    },
    addPwdDialog() {
      this.addPwdDialogVisible = true
    },
    handleAddPwdDialogClose(done) {
      this.newPwd = ''
      done()
    },
  },
}
</script>

<style lang="scss"></style>
