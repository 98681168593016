<template>
  <div class="activity">
    <!-- 活动管理 page -->
    <div>
      <el-table :data="tableData" stripe style="width: 100%;">
        <el-table-column prop="id" label="序号" width="100px"></el-table-column>
        <el-table-column prop="name" label="活动名称" >
          <template scope="scope">
            <el-input
              v-model="scope.row.name"
              @change="updateactivity(scope.row.id, scope.row.name)"
            ></el-input>
          </template>
        </el-table-column>

        <el-table-column prop="state" label="使用中">
          <template scope="scope">
            <el-switch
              v-model="scope.row.state"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="2"
              @change="useactivity(scope.row.id, scope.row.state)"
            ></el-switch>
          </template>
        </el-table-column>

        <el-table-column prop="" label="操作">
          <template scope="scope">
            <el-button @click="jump(scope.row.id)" size="small">
              编 辑
            </el-button>
            <el-button
              type="danger"
              @click="deleteactivity(scope.row.id)"
              size="small"
            >
              删 除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="buttonsnew">
        <el-button type="primary"  @click="addactive">新增</el-button>
      </div>
      <div class="buttonsave">
        <el-button type="success"  @click="saveactive()">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  UPLOAD_IMG_URL,
  reqGetAllConfig,
  reqSaveAllConfig,
  activityupdate,
  activityselect,
  activityuse,
  activitydel,
  activitycreate,
} from '@/api'

export default {
  name: 'Home',
  components: {},
  data() {
    return {
      // 测试数据 begin
      tableData: [],
      //  测试数据 end
    }
  },
  mounted() {
    this.sel()
  },
  methods: {
    sel() {
      activityselect().then((res) => {
        this.tableData = res.data
      })
    },
    deleteactivity(id) {
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error',
      }).then(() => {
        activitydel({ id }).then((res) => {
          if (res.code === 20031) {
            this.$alert('删除成功', {
              confirmButtonText: '确定',
            })
          }
          this.sel()
        })
      })
    },
    updateactivity(id, name) {
      activityupdate({ id, name }).then((res) => {})
    },
    useactivity(id, state) {
      activityuse({ id, state }).then((res) => {
        this.sel()
      })
    },
    jump(id) {
      this.$router.push({ path: '/editActivity', query: { id } })
    },
    addactive() {
      this.tableData.push({})
    },
    saveactive() {
      this.tableData.forEach((v) => {
        if (!v.id) {
          activitycreate({ name: v.name }).then((res) => {
            if (res.code === 20021) {
              this.$alert('保存成功', {
                confirmButtonText: '确定',
              })
              this.sel()
            } else {
              this.$alert('保存失败', res.code, {
                confirmButtonText: '确定',
              })
            }
          })
        }
      })
    },
  },
}
</script>

<style></style>
