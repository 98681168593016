<template>
  <div class="weidao">
    <h3>昭化味道链接</h3>
    <el-input v-model="data.pageWeidaoBtnLink"></el-input>
    <el-button @click="save">保存</el-button>
  </div>
</template>

<script>
import {configselect, configsave} from "@/api"

export default {
  name: 'Weidao',
  components: {},
  data() {
    return {
      data: {}
    }
  },
  mounted() {
    configselect().then(res => {
      this.data = res.data
    })
  },
  methods: {
    save() {
      configsave(this.data).then(res => {
        this.$message.success(res.msg)
      })
    }
  }
}
</script>

<style lang="scss">

</style>
