<template>
  <div class="activity_edit" id="pagessssssssss">
    <!--  编辑活动  -->
    <!--    <el-button type="success" @click="test">test</el-button>-->
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="日程安排" name="first">
        <div v-for="(item, index) in form" :key="item.id">
          <el-card class="box-card">
            <div class="text item">
              <el-form ref="form" :model="item" label-width="100px">
                <el-form-item class="asdfxd" label="日程时间">
                  <el-date-picker
                    v-model="item.date"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item class="asdfxd" label="日程内容">
                  <!--                  <vue-tinymce class="tinydemo" :id="index+'asdff'"-->
                  <!--                               v-model="item.content"-->
                  <!--                               :setup="setup"-->
                  <!--                               :setting="setting"/>-->

                  <!-- <el-tiptap
                    v-model="item.content"
                    :extensions="extensions"
                  ></el-tiptap> -->
                   <rich-editor :content.sync="item.content" style="width:100%;height:500px"></rich-editor>
                </el-form-item>
                <el-form-item>
                  <el-button type="danger" @click="delthis(index)">
                    删除
                  </el-button>
                </el-form-item>
              </el-form>
            </div>
          </el-card>
        </div>
        <div class="buttonsnew">
          <el-button type="primary" @click="doadd">新增</el-button>
        </div>
        <div class="buttonsave">
          <el-button type="success" @click="doedit">保存</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="人员名单" name="second">
        <!--  姓名 单位及职位 房号 乘车号-->
        <div v-for="(item, index) in persion_form" :key="item.id">
          <el-form ref="persion_form" :model="item" label-width="100px">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                
                <!--                <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>-->
                <el-dropdown
                  style="float: right; padding: 3px 0; margin-right: 60px;"
                  class="zindex10000"
                >
                  <el-button type="primary" size="small">
                    操作
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item class="zindex10000">
                      <span @click="addperson(item.persons)">添加人员</span>
                    </el-dropdown-item>
                    <el-dropdown-item class="zindex10000">
                      <span @click="delperson(index)">删除选中</span>
                    </el-dropdown-item>
                    <el-dropdown-item class="zindex10000">
                      <span @click="delfrop(index)">删除本组</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>

                <span>组名 ：</span>
                <el-input class="item_name def-short-input" v-model="item.name"></el-input>
              </div>
              <div class="text item">
                <el-table
                  v-loading="loading"
                  :data="item.persons"
                  stripe
                  style="width: 100%;"
                  @selection-change="handleSelectionChange"
                >
                  <el-table-column
                    type="selection"
                    width="55"
                  ></el-table-column>

                  <el-table-column prop="name" label="人员姓名">
                    <template scope="scope">
                      <el-input v-model="scope.row.name"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="enterprise" label="单位及职位">
                    <template scope="scope">
                      <el-input v-model="scope.row.enterprise"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="roomNumber" label="房间号">
                    <template scope="scope">
                      <el-input v-model="scope.row.roomNumber"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="carNumber" label="备注">
                    <template scope="scope">
                      <el-input v-model="scope.row.carNumber"></el-input>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-card>
          </el-form>
        </div>
        <div>
          <div class="buttonsnew">
            <el-button type="primary" @click="doadd_persons">
              新增
            </el-button>
          </div>
          <div class="buttonsave">
            <el-button type="success" @click="doedit_persons">
              保存
            </el-button>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="考察点位" name="third">
        <div>
          <el-card class="box-card">
            <div>
              <!-- <el-tiptap
                v-model="kcdw.content"
                :extensions="extensions"
              ></el-tiptap> -->
              <rich-editor :content.sync="kcdw.content" style="width:100%;height:500px"></rich-editor>
              <!--              <vue-tinymce-->
              <!--                  v-model="kcdw.content"-->
              <!--                  :setup="setup"-->
              <!--                  :setting="kcdwsetting"/>-->
            </div>
          </el-card>
        </div>
        <div class="buttonsave">
          <el-button type="success" circle @click="savepoint">保存</el-button>
        </div>
      </el-tab-pane>
      <el-tab-pane label="餐叙方案" name="fourth">
        <div v-for="(item, index) in form2" :key="item.id">
          <el-card class="box-card">
            <div class="text item">
              <el-form ref="form" :model="item" label-width="100px">
                <el-form-item class="asdfxd" label="日程时间">
                  <el-date-picker
                    v-model="item.date"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item class="asdfxd" label="日程内容">
                  <!--                  <vue-tinymce-->
                  <!--                      v-model="item.content"-->
                  <!--                      :setup="setup"-->
                  <!--                      :setting="setting"/>-->
                  <!-- <el-tiptap
                    v-model="item.content"
                    :extensions="extensions"
                  ></el-tiptap> -->
                  <rich-editor :content.sync="item.content" style="width:100%;height:500px"></rich-editor>
                </el-form-item>
                <el-form-item>
                  <el-button type="danger" @click="delcx(index)">
                    删除
                  </el-button>
                </el-form-item>
              </el-form>
            </div>
          </el-card>
        </div>
        <div>
          <div class="buttonsnew">
            <el-button type="primary" @click="addcs">新增</el-button>
          </div>
          <div class="buttonsave">
            <el-button type="success" @click="mealsaveactivityId_fun">
              保存
            </el-button>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="温馨提示" name="five">
        <div v-for="(item) in form3" :key="item.id">
          <el-card class="box-card">
            <div class="dfgahb">
              <el-input
                v-model="item.title"
                placeholder="请输入标题"
              ></el-input>
            </div>
            <!--            <vue-tinymce-->
            <!--                v-model="item.content"-->
            <!--                :setup="setup"-->
            <!--                :setting="setting3"/>-->
            <!-- <el-tiptap
              v-model="item.content"
              :extensions="extensions"
            ></el-tiptap> -->
            <rich-editor :content.sync="item.content" style="width:100%;height:500px"></rich-editor>
            <div class="agase">
              <el-button type="danger" @click="promptdel">删除</el-button>
            </div>
          </el-card>
        </div>
        <div class="buttonsnew">
          <el-button type="primary" @click="promptadd">新增</el-button>
        </div>
        <div class="buttonsave">
          <el-button type="success" @click="promptsave">保存</el-button>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {
  schedulenow,
  schedulesave,
  personGroupid,
  personGroupsave,
  pointid,
  pointsave,
  schedulemealid,
  schedulemealget,
  mealsaveactivityId,
  prompt,
  promptsave,
} from '@/api'
import {
  // necessary extensions
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Underline,
  Italic,
  Strike,
  ListItem,
  BulletList,
  OrderedList,
  Link,
  Image,
  Iframe,
  CodeBlock,
  Blockquote,
  TodoItem,
  TodoList,
  TextAlign,
  FontSize,
  FontType,
  SelectAll,
  Fullscreen,
  Preview,
  TextHighlight,
  TextColor,
  FormatClear,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  History,
  TrailingNode,
  HardBreak,
  HorizontalRule,
  LineHeight,
  Indent,
} from 'element-tiptap'

// import '@wangeditor/editor/dist/css/style.css'
// import {createEditor, createToolbar, IEditorConfig, IDomEditor} from '@wangeditor/editor'

export default {
  name: 'Home',
  components: {},
  data() {
    return {
      extensions: [
        new Doc(),
        new Text(),
        new Paragraph(),
        new Heading({ level: 6 }),
        new Bold({ bubble: true }), // 在气泡菜单中渲染菜单按钮
        new Underline({ bubble: true, menubar: false }), // 在气泡菜单而不在菜单栏中渲染菜单按钮
        new Italic(),
        new Strike(),
        new ListItem(),
        new BulletList(),
        new OrderedList(),
        new Link(),
        new Image(),
        // {
        // 默认会把图片生成 base64 字符串和内容存储在一起，如果需要自定义图片上传
        // uploadRequest(file) {
        //   如果接口要求 Content-Type 是 multipart/form-data，则请求体必须使用 FormData
        //   const fd = new FormData()
        //   fd.append('image', file)
        //   第1个 return 是返回 Promise 对象
        //   为什么？因为 axios 本身就是返回 Promise 对象
        //   return uploadImage(fd).then(res => {
        //     // 这个 return 是返回最后的结果
        //     return res.data.data.url
        //   })
        // } // 图片的上传方法，返回一个 Promise<url>
        // }
        new Iframe(),
        new CodeBlock(),
        new Blockquote(),
        new TodoItem(),
        new TodoList(),
        new TextAlign(),
        new FontSize(),
        new FontType(),
        new SelectAll(),
        new Fullscreen(),
        new Preview(),
        new TextHighlight(),
        new TextColor(),
        new FormatClear(),
        new Table({ resizable: true }),
        new TableHeader(),
        new TableCell(),
        new TableRow(),
        new History(),
        new TrailingNode(),
        new HardBreak(),
        new HorizontalRule(),
        new LineHeight(),
        new Indent(),
      ],
      editor: null,
      save: false,
      activeName: 'first',
      activityID: false,
      form: [],
      editors: [],
      setting: {
        height: 300,
      },

      //  人员名单
      persion_form: [],
      multipleSelection: '',
      selectedIds: '',
      checkedCities: '',
      loading: false,

      //考察点位
      kcdw: { content: '请编写' },
      kcdwsetting: {
        height: 600,
      },
      //
      form2: [],
      // tishi
      form3: [{ title: '', content: '' }],
      setting3: {
        height: 300,
      },
    }
  },
  mounted() {
    this.activityID = this.$route.query.id
    this.schedulenow_fun(this.activityID)

    setTimeout(() => {}, 300)

    // 人员名单
    this.personGroupid_fun(this.activityID)

    // 考察点位
    this.pointid_fun(this.activityID)

    //
    this.schedulemealid_fun()
    this.prompt_fun()
  },
  methods: {
    // 日程安排 begin
    handleClick() {},
    schedulenow_fun(id) {
      schedulenow(id).then((res) => {
        this.form = res.data
        // console.log(this.form)
      })
    },
    doedit() {
      schedulesave(this.activityID, this.form).then((res) => {
        if (res.code === 20041) {
          this.$alert('保存成功', {
            confirmButtonText: '确定',
          })
        } else {
          this.$alert('保存失败', res.code, {
            confirmButtonText: '确定',
          })
        }
      })
    },
    setup(editor) {
      // console.log(editor)
    },
    doadd() {
      //scrollIntoView
      this.form.push({
        activityId: this.activityID,
        content: '',
        date: '',
      })
      setTimeout(() => {
        window.scrollTo(0, 999999)
      }, 300)
    },
    delthis(e) {
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.form.splice(e, 1)
          this.doedit()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    // 日程安排 end
    uuid(len = 12, radix = 16) {
      let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split(
        '',
      )
      let uuid = [],
        i
      radix = radix || chars.length
      if (len) {
        for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)]
      } else {
        let r
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
        uuid[14] = '4'
        for (i = 0; i < 36; i++) {
          if (!uuid[i]) {
            r = 0 | (Math.random() * 16)
            uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r]
          }
        }
      }
      return uuid.join('')
    },
    personGroupid_fun(id) {
      personGroupid(id).then((res) => {
        this.persion_form = res.data
        this.persion_form.forEach((v, k) => {
          v.persons.forEach((j, l) => {
            j.uid = this.uuid()
          })
        })
      })
    },
    addperson(e) {
      let uid = this.uuid()
      e.push({
        carNumber: '',
        enterprise: '',
        name: '',
        roomNumber: '',
        uid: uid,
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    doadd_persons() {
      this.persion_form.push({
        name: '',
        persons: [],
      })
      personGroupsave(this.persion_form, this.activityID)
    },
    doedit_persons() {
      this.loading = true
      personGroupsave(this.persion_form, this.activityID).then((res) => {
        if (res.code === 20041) {
          this.$alert('保存成功', {
            confirmButtonText: '确定',
          })
        } else {
          this.$alert('保存失败', res.code, {
            confirmButtonText: '确定',
          })
        }
        this.loading = false
      })
    },
    delperson(i) {
      this.loading = true
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.persion_form.forEach((v, k) => {
            v.persons.forEach((j, l) => {
              this.multipleSelection.forEach((e) => {
                if (e.uid === j.uid) {
                  v.persons.splice(l, 1)
                }
              })
            })
          })
          this.doedit_persons()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
          this.loading = false
        })
    },
    delfrop(i) {
      this.loading = true
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.persion_form.splice(i, 1)
          this.doedit_persons()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
          this.loading = false
        })
    },

    //  考察点位

    pointid_fun(id) {
      pointid(id).then((res) => {
        this.kcdw = res.data
      })
    },
    savepoint() {
      pointsave(this.activityID, this.kcdw).then((res) => {
        if (res.code === 20041) {
          this.$alert('保存成功', {
            confirmButtonText: '确定',
          })
        } else {
          this.$alert('保存失败', res.code, {
            confirmButtonText: '确定',
          })
        }
      })
    },

    //餐叙方案
    schedulemealid_fun() {
      schedulemealget(this.activityID).then((res) => {
        this.form2 = res.data
      })
    },
    mealsaveactivityId_fun() {
      mealsaveactivityId(this.activityID, this.form2).then((res) => {
        if (res.code === 20041) {
          this.$alert('保存成功', {
            confirmButtonText: '确定',
          })
        } else {
          this.$alert('保存失败', res.code, {
            confirmButtonText: '确定',
          })
        }
      })
    },
    delcx(i) {
      this.form2.splice(i, 1)
      this.mealsaveactivityId_fun()
    },
    addcs() {
      this.form2.push({})
    },

    //tishi

    prompt_fun() {
      prompt(this.activityID).then((res) => {
        this.form3 = res.data
        console.log(this.form3)
      })
    },
    promptadd() {
      this.form3.push({})
    },
    promptdel(i) {
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.form3.splice(i, 1)
          this.promptsave()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
          this.loading = false
        })
    },
    promptsave() {
      promptsave(this.activityID, this.form3).then((res) => {
        if (res.code === 20041) {
          this.$alert('保存成功', {
            confirmButtonText: '确定',
          })
          this.prompt_fun()
        } else {
          this.$alert('保存失败', res.code, {
            confirmButtonText: '确定',
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
.agase {
  margin-top: 20px;
}

.dfgahb {
  margin-bottom: 20px;
}

.box-card {
  margin: 0 20px 20px 20px;
}

.border {
  border: 1px solid #606266;
}

.text-area {
  height: 200px;
}

.buttonsave {
  float: right;
  bottom: 100px;
  right: 30px;
  position: fixed;
  z-index: 9999;
}

.buttonsnew {
  float: right;

  bottom: 150px;
  right: 30px;
  position: fixed;
  z-index: 9999;

  // width: 100px;
  // height: 100px;
  // background-color: #000;
  // .el-button {
  //   border-radius: 9999px;
  // }
}

.item_name {
  width: 300px;
}

.zindex10000 {
  z-index: 10000;
}
</style>
<style>
.el-form-item__label {
  height: 40px;
  line-height: 40px;
}

.el-tiptap-editor__menu-bubble {
  display: flex;
}

.fa-icon {
  margin-left: 10px;
}

el-tooltip el-tiptap-editor__command-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
