import RichEditor from '@/components/rich-editor/component';
import '@/components/rich-editor/component/core/themes/default/default.css';
import '@/components/rich-editor/component/core/kindeditor-all-min.js';
import '@/components/rich-editor/component/core/lang/zh-CN.js';

const install = function (Vue) {
  if (install.installed) return;
  install.installed = true;
  Vue.component('rich-editor', RichEditor);
};
export default install;
