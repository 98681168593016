<template>
  <div class="config">
    <div class="config-img-box">
      <section
        v-for="(v, k) in data"
        :key="k"
        v-show="k != 'id' && k != 'pageWeidaoBtnLink'"
      >
        <h3>{{ getName(k) }}</h3>
        <el-upload
          class="avatar-uploader"
          :action="UPLOAD_IMG_URL"
          :show-file-list="false"
          :on-success="
            (res, file) => {
              homeBgUploadSuccess(res, file, k)
            }
          "
          :before-upload="handleBeforeImgUpload"
        >
          <img v-if="v" :src="v" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </section>
    </div>
    <!--    <div>-->
    <!--      <h3>昭化味道链接</h3>-->
    <!--      <el-input v-model="data.pageWeidaoBtnLink"></el-input>-->
    <!--      <el-button @click="save">保存</el-button>-->
    <!--    </div>-->
  </div>
</template>

<script>
import { UPLOAD_IMG_URL, configselect, configsave } from '@/api'

export default {
  name: 'Config',
  components: {},
  data() {
    return {
      UPLOAD_IMG_URL,
      data: {},
    }
  },
  mounted() {
    configselect().then((res) => {
      this.data = res.data
      console.log(this.data)
    })
  },
  methods: {
    getName(k) {
      switch (k) {
        case 'homeBg':
          return '首页背景'

        case 'homeScheduleBtnBg':
          return '日程安排按钮背景'
        case 'homePersonBtnBg':
          return '人员名单按钮背景'
        case 'homePointBtnBg':
          return '考察点位按钮背景'
        case 'homeMealBtnBg':
          return '餐叙方案按钮背景'
        case 'homePromptBtnBg':
          return '温馨提升按钮背景'
        case 'homeLizhouBtnBg':
          return '昭化区情按钮背景'
        case 'homeJinquBtnBg':
          return '景区简介按钮背景'
        case 'homeWeidaoBtnBg':
          return '昭化味道按钮背景'
        case 'pageHomeTitleBg':
          return '首页标题'
        case 'pageHomeSubtitleBg':
          return '首页副标题'
        case 'pageScheduleTitleBg':
          return '日程安排标题'

        case 'pageLoginTitleBg':
          return '登录页面标题'
        case 'pagePersonTitleBg':
          return '人员名单标题'
        case 'pagePointTitleBg':
          return '考察点位标题'
        case 'pageMealTitleBg':
          return '餐叙方案标题'
        case 'pagePromptTitleBg':
          return '温馨提升标题'
        case 'pageLizhouTitleBg':
          return '昭化区情标题'
        case 'pageJinquTitleBg':
          return '景区简介标题'
        case 'detailBg':
          return '详情页背景'
        default:
          return k
      }
    },
    homeBgUploadSuccess(res, file, k) {
      if (res.code === 20051) {
        this.data[k] = res.data
        this.save()
      }
    },
    save() {
      configsave(this.data).then((res) => {
        this.$message.success(res.msg)
      })
    },
    handleBeforeImgUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isLt2M
    },
  },
}
</script>

<style lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  max-width: 200px;
  // width: 178px;
  // height: 178px;
  display: block;
}

.config-img-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
