<template>
  <div>
    <rich-editor :content.sync="data.content" style="width:100%;min-height:500px"></rich-editor>
    <el-button @click="save">保存</el-button>
  </div>
</template>

<script>
import {jinquselect, jinqusave} from "@/api"

import {
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Underline,
  Italic,
  Strike,
  ListItem,
  BulletList,
  OrderedList,
  Link,
  Image,
  Iframe,
  CodeBlock,
  Blockquote,
  TodoItem,
  TodoList,
  TextAlign,
  FontSize,
  FontType,
  SelectAll,
  Fullscreen,
  Preview,
  TextHighlight,
  TextColor,
  FormatClear,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  History,
  TrailingNode,
  HardBreak,
  HorizontalRule,
  LineHeight,
  Indent,
  CodeView
} from 'element-tiptap';

export default {
  name: 'Config',
  components: {},
  data() {
    return {
      data: {},
      extensions: [
        new Doc(),
        new Text(),
        new Paragraph(),
        new Heading({level: 6}),
        new Bold({bubble: true}), 
        new Underline({bubble: true, menubar: false}),
        new Italic(),
        new Strike(),
        new ListItem(),
        new BulletList(),
        new OrderedList(),
        new Link(),
        new Image(),
        new Iframe(),
        new CodeBlock(),
        new Blockquote(),
        new TodoItem(),
        new TodoList(),
        new TextAlign(),
        new FontSize(),
        new FontType(),
        new SelectAll(),
        new Preview(),
        new TextHighlight(),
        new TextColor(),
        new FormatClear(),
        new Table({resizable: true}),
        new TableHeader(),
        new TableCell(),
        new TableRow(),
        new History(),
        new TrailingNode(),
        new HardBreak(),
        new HorizontalRule(),
        new LineHeight(),
        new Indent(),
        new CodeView()
      ]
    }
  },
  mounted() {
    jinquselect().then(res => {
      this.data = res.data
    })
  },
  methods: {
    save() {
      jinqusave(this.data).then(res => {
        this.$message.success(res.msg)
      })
    },
  }
}
</script>

<style lang="scss">

</style>
