import Home from '@/views/home'
import activity from '@/views/activity'
import editActivity from '@/views/activity/editActivity.vue'
import config from '@/views/config'
import lizhou from '@/views/lizhou'
import jinqu from '@/views/jinqu'
import weidao from '@/views/weidao'
import pwd from '@/views/pwd'

export default [
  {
    path: '/',
    name: 'config',
    component: config,
    meta: { name: '全局配置', show: true, icon: 'el-icon-s-home', login: true },
  },
  {
    path: '/pwd',
    name: 'pwd',
    component: pwd,
    meta: { name: '密码管理', show: true, icon: 'el-icon-s-home', login: true },
  },
  {
    path: '/activity',
    name: 'activity',
    component: activity,
    meta: { name: '活动管理', show: true, icon: 'el-icon-s-home', login: true },
  },
  {
    path: '/editActivity',
    name: 'editActivity',
    component: editActivity,
    meta: {
      name: '编辑活动',
      show: false,
      icon: 'el-icon-s-home',
      login: true,
    },
  },
  {
    path: '/lizhou',
    name: 'lizhou',
    component: lizhou,
    meta: { name: '昭化区情', show: true, icon: 'el-icon-s-home', login: true },
  },
  {
    path: '/jinqu',
    name: 'jinqu',
    component: jinqu,
    meta: { name: '景区简介', show: true, icon: 'el-icon-s-home', login: true },
  },
  {
    path: '/weidao',
    name: 'weidao',
    component: weidao,
    meta: { name: '昭化味道', show: true, icon: 'el-icon-s-home', login: true },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login'),
    meta: { name: '用户登录', show: false, login: false },
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404'),
    meta: { name: '抱歉,您访问的页面出错了', show: false, login: false },
  },
  {
    path: '*',
    redirect: '/404',
    meta: { name: '抱歉,您访问的页面出错了', show: false, login: false },
  },
]
