<template>
  <div class="home">

  </div>
</template>

<script>
import { UPLOAD_IMG_URL, reqGetAllConfig, reqSaveAllConfig } from '@/api'

export default {
  name: 'Home',
  components: {},
  data() {
    return {
      
    }
  },
  mounted() {
   
  },
  methods: {
  },
}
</script>

<style lang="scss">

</style>
